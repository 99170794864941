<template>
  <Modal :open="props.open" title="Rename folder" @close="onClose">
    <template #default>
      <div class="mt-2">
        <FormInput
          label="Name"
          name="name"
          autocomplete="name"
          placeholder="Folder Name"
          class="sm:col-span-6"
          type="text"
          :rules="['required']"
          :value="title"
          data-cy="folder-rename-input"
          @click.prevent
          @input="title = $event"
          @invalid="invalidTitle = $event"
        />
      </div>
    </template>
    <template #actions>
      <ButtonBase
        primary
        :loading="isLoading"
        class="ml-2"
        data-cy="apply-folder-rename-button"
        @click="onRename"
        >Rename</ButtonBase
      >
      <ButtonBase neutral @click="onClose">Close</ButtonBase>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  folderId: {
    type: Number,
    required: true,
  },
});

const router = useRouter();
const route = useRoute();
const { notify } = useNotifications();
const folderStructureStore = useFolderStructureStore();
const isLoading = ref(false);
const title = ref("");
const invalidTitle = ref(false);

const onClose = () => {
  router.push({
    path: route.path,
    query: {
      ...route.query,
      folderId: undefined,
      mode: undefined,
    },
  });
};

watch(
  () => props.folderId,
  () => {
    setFolder();
  },
);

onMounted(() => {
  setFolder();
});

const setFolder = () => {
  const folder = folderStructureStore.getFolderById(props.folderId);
  if (folder) {
    title.value = folder.title;
  }
};

const onRename = async () => {
  isLoading.value = true;
  const errorMaybe = await folderStructureStore.updateFolderMetadata({
    folderId: props.folderId,
    title: title.value === "" || title.value == null ? "Untitled" : title.value,
  });
  notify(errorMaybe, "Folder renamed");
  if (!errorMaybe) {
    onClose();
  }
  isLoading.value = false;
};
</script>
