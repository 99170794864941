<template>
  <Modal :open="open" title="Refer a Friend " @close="onClose">
    <template #default>
      <div
        class="rounded-md bg-gray-100 shadow-sm border px-3 py-2 flex items-center my-6"
      >
        <div class="flex">
          <span class="text-sm mr-2 font-semibold">Your Affiliate Code:</span
          ><span class="text-sm text-gray-500">{{ affiliateCode }}</span>
        </div>
        <ButtonBase neutral xs class="ml-auto" @click="copyAffiliateCode">
          <DocumentDuplicateIcon class="mr-1 h-4 w-4" />Copy
        </ButtonBase>
      </div>
      <h3 class="font-semibold text-gray-900 mb-4">
        Frequently Asked Questions
      </h3>
      <dl class="space-y-4">
        <Disclosure
          v-for="faq in faqs"
          :key="faq.question"
          v-slot="slotProps"
          as="div"
          class="rounded-md bg-white shadow-sm border p-4"
        >
          <dt>
            <DisclosureButton
              class="flex w-full items-start justify-between text-left text-gray-900"
            >
              <span class="text-sm font-semibold">{{ faq.question }}</span>
              <span class="ml-6 flex items-center">
                <PlusIcon
                  v-if="!slotProps.open"
                  class="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <MinusIcon
                  v-else
                  class="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-xs text-gray-500">{{ faq.answer }}</p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import {
  DocumentDuplicateIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/vue/24/outline";
import { copyTextToClipboard } from "~/shared/utils";

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const router = useRouter();
const route = useRoute();

const onClose = () => {
  router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: undefined,
      boardId: undefined,
    },
  });
};

const faqs = [
  {
    question: "How much can I earn?",
    answer:
      "Once a referral uses your code you get 10% of the total amount paid by the referral as long as they are subscribed.",
  },
  {
    question: "How much does the referral save?",
    answer:
      "The referral saves 10% off on their subscription as long as they are subscribed.",
  },
  {
    question: "What's the process to refer someone?",
    answer:
      "Simply share your code with your clients, friends, online communities, or anyone you think would benefit from our service. " +
      "Once they sign up and add their payment method, you will start earning your commission.",
  },
  {
    question: "How do I get paid my commission?",
    answer:
      "Once your referral adds their payment method using your code, you will get a monthly payout to your bank account.",
  },
  {
    question:
      "As an agency owner, can I use my affiliate code with my clients?",
    answer:
      "Absolutely! Feel free to use your affiliate code with anyone from clients, friends, online communities and more. " +
      "If you have your clients in your workspace, we can help you extracting the clients into a separate workspace." +
      "This way you can save usage in your own workspace and still earn commission from your clients. " +
      "Please reach out to us if you would like to do so.",
  },
  {
    question: "Can I earn more than 10% commission?",
    answer:
      "Yes, you can decide to give away the full 20% commission to your referral, or you can keep the full 20% commission for yourself. " +
      "Please reach out to us if you would like to change your commission rate.",
  },
];

const appUserStore = useAppUserStore();
const { appUserMe } = storeToRefs(appUserStore);
const { getActiveWorkspace } = useWorkspace();
const workspace = computed(() => getActiveWorkspace(appUserMe.value));
const affiliateCode = computed(() =>
  workspace.value != null ? workspace.value.affiliateId : null,
);

const notificationStore = useNotificationStore();

const copyAffiliateCode = () => {
  if (affiliateCode.value) {
    copyTextToClipboard(affiliateCode.value);
    notificationStore.notify({
      type: "success",
      message: "Code copied",
    });
  }
};
</script>
