<template>
  <div class="lg:pl-72">
    <div
      v-if="showNavbar"
      class="sticky top-0 z-30 flex shrink-0 items-center gap-x-4 bg-yellow-50 px-4 sm:gap-x-6 sm:px-6 lg:px-8 py-2"
    >
      <div class="flex items-center flex-1 gap-x-4 self-stretch">
        <div class="flex-shrink-0">
          <ExclamationTriangleIcon
            class="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <p class="text-xs text-yellow-700">
          <span class="font-semibold mr-1">Action Required!</span
          ><span class="mr-1">{{ message }}</span>
        </p>
        <nuxt-link
          to="/settings/connections"
          class="text-xs text-yellow-700 underline -ml-2"
          >View connections &rarr;</nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import { ConnectionStatus } from "~/types/Connection";

const connectionStore = useConnectionStore();
const connectionsNotOK = computed(() =>
  connectionStore.connections.filter(
    (connection) =>
      connection.status !== ConnectionStatus.FETCHING_INITIAL_DATA &&
      connection.status !== ConnectionStatus.OK,
  ),
);
const showNavbar = computed(() => connectionsNotOK.value.length > 0);
const message = computed(() => {
  const count = connectionsNotOK.value.length;
  if (count === 1) {
    return "There is 1 connection with problems.";
  }
  return `There are ${count} connections with problems.`;
});
</script>
