<template>
  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-72 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      class="flex grow flex-col gap-y-1 overflow-y-auto border-r border-gray-200 bg-white px-6"
    >
      <SkeletonText v-if="loading" class="mt-8" />
      <template v-else>
        <div class="flex h-16 shrink-0 items-center">
          <img
            v-if="clients.length <= 0 || !activeClient"
            class="h-8 w-auto"
            src="~/assets/img/logo-wide.png"
            alt="DatAds"
          />
          <SidebarClientSelect
            v-else
            :clients="clients"
            :active-client="activeClient"
          />
        </div>
        <SidebarNav
          :navigation="navigation"
          :folders="folders"
          :can-manage="canManage"
          :reports="reports"
          :boards="boards"
          :testing-log-reports="testingLogReports"
          :launch-reports="launchReports"
          :has-testing-log-report="hasTestingLogReport"
          :has-launch-report="hasLaunchReport"
          :has-inspiration-board="hasInspirationBoard"
          @create:report="onCreateReport"
          @delete:report="onDeleteReport"
          @share:report="onShareReport"
          @create:testinglog:report="onCreateTestingLogReport"
          @delete:testinglog:report="onDeleteTestingLogReport"
          @share:testinglog:report="onShareTestingLogReport"
          @create:launch:report="onCreateLaunchReport"
          @delete:launch:report="onDeleteLaunchReport"
          @share:launch:report="onShareLaunchReport"
          @create:board="onCreateBoard"
          @delete:board="onDeleteBoard"
          @share:board="onShareBoard"
          @toggle:folder="onToggleFolder"
          @create:folder="onCreateFolder"
          @delete:folder="onDeleteFolder"
          @rename:folder="onRenameFolder"
          @duplicate:folder="onDuplicateFolder"
        />
      </template>
    </div>
    <div class="border-t border-r border-gray-200 bg-white shadow-sm">
      <div class="px-6 mt-2">
        <ButtonBase neutral class="w-full" @click="onOpenReferModal">
          <GiftIcon class="h-5 w-5 mr-2" />
          Refer a Friend
          <span
            class="inline-flex items-center rounded-md border border-gray-300 shadow-sm bg-white px-2 py-1 text-xs font-medium text-gray-600 ml-2"
            >20% off</span
          >
        </ButtonBase>
      </div>
      <SidebarProfileMenu />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import { GiftIcon } from "@heroicons/vue/24/outline";
import { Client } from "~/types/Client";
import {
  AnyCreativeReport,
  CreativeReportType,
} from "~/types/AnyCreativeReport";
import { Folder } from "~/types/FolderStructure";
import { InspirationBoard } from "~/types/InspirationBoard";
import { TestingLogReport } from "~/types/TestingLog";
import { LaunchReport } from "~/types/LaunchReport";

defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
  navigation: {
    type: Array as PropType<
      {
        name: string;
        href: string;
        icon: FunctionalComponent<HTMLAttributes & VNodeProps, {}, any>;
        current: boolean;
        indicatorNumber: number;
        dataCy: string;
      }[]
    >,
    required: true,
    default: () => [],
  },
  clients: {
    type: Array as PropType<Client[]>,
    required: true,
    default: () => [],
  },
  activeClient: {
    type: Object as PropType<Client | null>,
    required: false,
    default: null,
  },
  folders: {
    type: Array as PropType<Folder[]>,
    required: true,
    default: () => [],
  },
  canManage: {
    type: Boolean,
    required: true,
    default: false,
  },
  reports: {
    type: Array as PropType<AnyCreativeReport[]>,
    required: true,
    default: () => [],
  },
  boards: {
    type: Array as PropType<InspirationBoard[]>,
    required: true,
    default: () => [],
  },
  testingLogReports: {
    type: Array as PropType<TestingLogReport[]>,
    required: true,
    default: () => [],
  },
  hasTestingLogReport: {
    type: Boolean,
    required: true,
  },
  launchReports: {
    type: Array as PropType<LaunchReport[]>,
    required: true,
    default: () => [],
  },
  hasLaunchReport: {
    type: Boolean,
    required: true,
  },
  hasInspirationBoard: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  "create:report",
  "delete:report",
  "share:report",
  "create:board",
  "delete:board",
  "share:board",
  "create:testinglog:report",
  "delete:testinglog:report",
  "share:testinglog:report",
  "create:launch:report",
  "delete:launch:report",
  "share:launch:report",
  "toggle:folder",
  "create:folder",
  "delete:folder",
  "rename:folder",
  "duplicate:folder",
]);

const onToggleFolder = (folderId: number) => {
  emit("toggle:folder", folderId);
};

const onCreateFolder = () => {
  emit("create:folder");
};

const onDeleteFolder = (folderId: number) => {
  emit("delete:folder", folderId);
};

const onDeleteBoard = (file: { id: string; type: string }) => {
  emit("delete:board", file);
};

const onDeleteReport = (file: { id: string; type: string }) => {
  emit("delete:report", file);
};

const onDeleteTestingLogReport = (file: { id: string; type: string }) => {
  emit("delete:testinglog:report", file);
};

const onDeleteLaunchReport = (file: { id: string; type: string }) => {
  emit("delete:launch:report", file);
};

const onShareBoard = (file: { id: string; type: string }) => {
  emit("share:board", file);
};

const onShareReport = (file: { id: string; type: string }) => {
  emit("share:report", file);
};

const onShareTestingLogReport = (file: { id: string; type: string }) => {
  emit("share:testinglog:report", file);
};

const onShareLaunchReport = (file: { id: string; type: string }) => {
  emit("share:launch:report", file);
};

const onCreateReport = (dto: {
  type: CreativeReportType;
  folderId: number;
}) => {
  emit("create:report", dto);
};

const onCreateBoard = (folderId: number) => {
  emit("create:board", folderId);
};

const onCreateTestingLogReport = (folderId: number) => {
  emit("create:testinglog:report", folderId);
};

const onCreateLaunchReport = (folderId: number) => {
  emit("create:launch:report", folderId);
};

const onRenameFolder = (folderId: number) => {
  emit("rename:folder", folderId);
};

const onDuplicateFolder = (folderId: number) => {
  emit("duplicate:folder", folderId);
};

const onOpenReferModal = async () => {
  const router = useRouter();
  const route = useRoute();
  await router.push({
    path: route.path,
    query: {
      ...route.query,
      mode: "refer",
    },
  });
};
</script>
